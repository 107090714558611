import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import RedoIcon from "@mui/icons-material/Redo";
import Tooltip from "@mui/material/Tooltip";
import { RECORD_ORIGIN, RECORD_STATUS } from "../../constantsStatus";
import { getDuration } from "../../utils/momentHelpers";
import NotFoundTableRow from "../NotFoundTableRow";
// import DutyStatus from "../../components/DutyStatus";

import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.settingPage;

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto",
        padding: 10
    },
    table: {
        minWidth: 700,
        marginTop: theme.spacing(1)
    },
    tableHead: {
        padding: 5,
        minWidth: 130
    },
    tableRowTitle: {
        display: "inline-block",
        width: 95
    },
    capitalize: {
        textTransform: "capitalize"
    },
    saveIcon: {
        color: "#009be5",
        cursor: "pointer"
    }
});

class ReduxTableRow extends React.Component {
    handleAssign = (e, item) => {
        this.props.handleAssignDriver(e, item);
    };

    render() {
        const { classes, item = {} } = this.props;
        let isLocked = item.locked;
        let duration;
        const toolTipText = item.locked
            ? "Waiting for driver to accept or reject."
            : "Assign to driver";

        const activeEvents = ["ACTIVE", "INACTIVE_CHANGE_REJECTED"];

        if (!activeEvents.includes(item.recordStatus)) {
            isLocked = true;
        }
        if (item.eventTime && item.endEventTime) {
            duration = getDuration(moment(item.eventTime), moment(item.endEventTime))
        }
        else {
            duration = "";
        }
        return (
            <TableRow hover>
                <TableCell>
                    <div className={classes.capitalize}>
                        {item.vehicle && <div>{item.vehicle.name}</div>}
                    </div>
                </TableCell>
                {/* <TableCell> */}
                {/* <DutyStatus
                        status={item}
                        eventType={item.eventType}
                        eventCode={item.eventCode}
                        annotation={item.annotation}
                        eventSubType={item.eventSubType}
                    /> */}
                {/* </TableCell> */}
                <TableCell>
                    <div>{this.props.formatDate(item.eventTime)}</div>
                    <div>{this.props.formatTime(item.eventTime)}</div>
                </TableCell>
                <TableCell>
                    {item.endEventTime ? (
                        <div>{this.props.formatDate(item.endEventTime)}</div>
                    ) : (
                        ""
                    )}
                    {item.endEventTime ? (
                        <div>{this.props.formatTime(item.endEventTime)}</div>
                    ) : (
                        ""
                    )}
                </TableCell>
                <TableCell>{duration}</TableCell>
                <TableCell>{RECORD_STATUS[item.recordStatus]}</TableCell>
                <TableCell>{RECORD_ORIGIN[item.recordOrigin]}</TableCell>
                <TableCell>{item.location}</TableCell>
                <TableCell>
                    <Tooltip
                        title={toolTipText}
                        aria-label="assign"
                        placement="left"
                    >
                        <div>
                            <IconButton
                                aria-label="delete"
                                className={classes.margin}
                                size="small"
                                color={"primary"}
                                disabled={isLocked}
                                onClick={e => this.handleAssign(e, item)}
                            >
                                <RedoIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    {this.props.adminKey && this.props.isQa === "true" && item.sequenceId && (
                        <div>{item.sequenceId}</div>
                    )}
                </TableCell>
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                {LABELS.vehicle}
                            </TableCell>
                            {/* <TableCell className={classes.tableHead}>
                                Event code
                            </TableCell> */}
                            <TableCell className={classes.tableHead}>
                                {LABELS.startTime}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.endTime}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.duration}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.recordStatus}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                             {LABELS.recordOrigin}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.location}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.assign}
                            </TableCell>
                            {this.props.adminKey && this.props.isQa === "true" && (
                                <TableCell >
                                    Sq Id
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    formatDate={this.props.formatDate}
                                    formatTime={this.props.formatTime}
                                    handleAssignDriver={
                                        this.props.handleAssignDriver
                                    }
                                    adminKey={this.props.adminKey}
                                    isQa={this.props.isQa}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelRowsPerPage={LABELS.rowsperpage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);
